import eventBus from '@/utils/eventBus'
export default {
    methods: {
        async changeStatus(sprint) {
            try {
                if (this.allData?.[1]?.list?.length === 0) throw new Error()
                this.loadingBtn = true
                const status = sprint.status === "new" ? 'in_process' : 'completed'
                let { data } = await this.$http.put(`tasks/sprint/${sprint.id}/update_status/`,
                    { status }
                )
                if (this.sprint) this.sprint.status = status
                if (this.sprint) this.sprint.dead_line = data.dead_line
                if (this.activeItems) this.activeItems = status !== 'completed' ? true : false

                eventBus.$emit("sprint_update_table", this.sprint)
                this.$emit('change')
            }
            catch (error) {
                console.log(error)
                this.$message.error(`Ошибка: ${error}`)
            }
            finally {
                this.loadingBtn = false
            }
        },
        getTimeInterval(timeInterval) {
            /*eslint-disable */
            switch (timeInterval) {
                case 'week': return "Неделя";
                case 'two_week': return "Две недели";
                case 'month': return "Месяц";
            }
            /*eslint-enable */
        },
        getStatus(status) {
            /*eslint-disable */
            switch (status) {
                case 'new': return { name: "Новый", color: "blue" }
                case 'in_process': return { name: "В процессе", color: "purple" };
                case 'completed': return { name: "Завершен", color: "green" };
            }
            /*eslint-enable */
        }
    },

}