<template>
    <div>
        <a-drawer
            :visible="visible"
            @close="visible = false"
            class="task_edit"
            :width="isMobile ? '100%' : 500"
            :title="edit ? 'Редактировать спринт' : 'Создать спринт'">
            <div class="d_body"> 
                <a-skeleton  active class="mb-8" :paragraph="{ rows: 7 }" :loading="loading"/>
                <a-form-model
                    v-show="!loading"
                    ref="sprintForm"
                    class="p-4"
                    :model="form"
                    :rules="rules">
                    <a-form-model-item
                        ref="name"
                        prop="name">
                        <a-input
                            v-model="form.name"
                            size="large"
                            placeholder="Название " />
                    </a-form-model-item>
                    <a-form-model-item
                        ref="target"
                        prop="target">
                        <a-input
                            v-model="form.target"
                            size="large"
                            placeholder="Цель" />
                    </a-form-model-item>
                  
                    <a-form-model-item
                        ref="time_interval"
                        prop="time_interval">
                        <a-radio-group 
                            class="w-full" 
                            :class="isMobile && 'flex flex-col'"
                            size="large" 
                            v-model="form.time_interval" >
                            <a-radio-button 
                                class="w-1/3 text-center" 
                                :class="isMobile && 'w-full radio_week'"
                                value="week">
                                Неделя
                            </a-radio-button>
                            <a-radio-button 
                                class="w-1/3 text-center" 
                                :class="isMobile && 'w-full radio_week'"
                                value="two_week">
                                Две недели
                            </a-radio-button>
                            <a-radio-button 
                                class="w-1/3 text-center"
                                :class="isMobile && 'w-full radio_week'"
                                value="month">
                                Месяц
                            </a-radio-button>
        
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item
                        ref="expected_result"
                        prop="expected_result">
                        <a-select v-model="form.expected_result" mode="tags" 
                                  style="width: 100%" size="large" placeholder="Ожидаемый результат" notFoundContent="Введите несколько значений">
                        
                        </a-select>
  
                    </a-form-model-item>

                    <a-button
                        :block="isMobile"
                        type="primary"
                        size="large"
                        class="mt-2"
                        :loading="loading"
                        @click="createAndUpdate">
                        {{edit ? 'Сохранить': 'Создать'}}
                    </a-button>
                </a-form-model>
            </div>

        </a-drawer> 
    </div>
</template>

<script>

export default {
    props: {
        value: Boolean,
        edit: Boolean,
        editData: Object,
        additionalData: {
            type: Object,
            default: null
        }
    },
    data(){
        return {
            form: {
                name: "",
                target: "",
                time_interval: "",
                expected_result: []
            },
            rules: {
                name: [
                    { required: true, message: this.$t('task.field_require'), trigger: 'blur' },
                    { max: 255, message: this.$t('task.field_min_require'), trigger: 'blur' }
                ],
                time_interval: [
                    { required: true, message: this.$t('task.field_require'), trigger: 'blur' }
                ]
            },
            loading: false,
            
        }
    },
    computed: {
        visible: {
            get(){
                return this.value
            },
            set(value){
                this.$emit('input', value)
            }
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    watch: {
        visible(val){
            if(val){
                if(this.edit){
                    this.form = this.editData
                }
            } else this.clear()
        }
    },
   
    methods: {
        clear(){
            this.form=  {
                name: "",
                target: "",
                time_interval: "",
                expected_result: []
            }
        },
        createAndUpdate(){
            this.$refs.sprintForm.validate(async valid => {
                if (valid) {
                    try{ 
                        // Для рабочей группы 
                        if(this.additionalData)
                            this.form = Object.assign( this.additionalData, this.form)

                        this.loading = true
                        if(this.edit) { 
                            const {data} = await this.$http.put(`tasks/sprint/${this.form.id}/update/`, this.form)
                            this.$emit("update", data)
                        } else {
                            const {data} = await this.$http.post(`tasks/sprint/create/`, this.form)
                            this.$emit("create", data)
                        }
                 
                    } catch(e) {
                        console.log(e)
                        this.$message.error(this.$t('task.error'))
                    } finally {
                      
                        this.loading = false
                        this.visible = false
                     
                    }
                }

            })
        }
    }
}
</script>

<style lang="scss" scoped>
.d_body{
        height: calc(100% - 40px);
        overflow-x: auto;
        .task_form_wrap{
            padding: 30px;
            .ck-editor__editable {
                max-height: 300px;
            }
        }
        .radio_week {
            border-radius: 8px;
            border: 1px solid #e1e7ec;
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
        }
    }
    .d_footer{
        display: flex;
        align-items: center;
        height: 40px;
        border-top: 1px solid #e8e8e8;
        padding-left: 30px;
        padding-right: 30px;
        .save_btn{
            margin-right: 5px;
        }
    }
</style>