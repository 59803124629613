<template>
    <a-button
        type="ui"
        :size="size"
        @click="openTableSettings"
        flaticon
        ghost
        icon="fi-rr-settings" />
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    props: {
        size: {
            type: String,
            default: 'large'
        },
        pageName: {
            type: String,
            required: true
        }
    },
    methods: {
        openTableSettings() {
            eventBus.$emit(`open_table_settings_${this.pageName}`)
        }
    }
}
</script>